<template>
  <div style="position:relative;">
    <el-tabs v-model="activeName" class="reportListTabs">
      <el-tab-pane label="" name="facebook">
        <template #label>
          <span id="firstStep">Facebook</span>
        </template>
        <FBReport />
      </el-tab-pane>
      <!-- <el-tab-pane label="TikTok" name="tiktok">
        <TTReport />
      </el-tab-pane> -->
    </el-tabs>
    <router-link to="/reportRecord" class="reportRecord">
      <span class="brandColor">报表导出记录</span>
    </router-link>
    <router-link to="/panel" class="backPanel">
      <span class="brandColor">返回</span>
    </router-link>
  </div>
</template>

<script>
import FBReport from './FBReportEn.vue'
import TTReport from './TTReport'
export default {
  name: 'ReportList',
  components: {
    FBReport,
    TTReport
  },
  data() {
    return {
      activeName: 'facebook'
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.reportListTabs {
  :deep(.el-tabs__header) {
    background: #fff;

    .el-tabs__nav-wrap {
      padding-left: 20px;
    }
  }
}

.reportRecord,
.backPanel {
  position: absolute;
  right: 20px;
  top: 10px;
  text-decoration: none;
  font-size: 14px;
}

.reportRecord {
  right: 70px;
}</style>
