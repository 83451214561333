<template>
   2
</template>

<script>
export default {
    name: 'TTReport',
    data() {
        return {

        };
    },
    methods: {

    },
};
</script>

<style lang="less" scoped>

</style>